.logout {
  position: absolute;
  top: 20px;
  right: 40px;
  cursor: pointer;

  i {
    margin-right: 10px;
  }
}

.options {
  display: grid;
  justify-content: center;
  align-items: center;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 20px;
  max-width: 1024px;
  margin: 0 auto;
}

.option {
  background-color: #fff;
  padding: 20px;
  margin-bottom: 10px;
  min-height: 200px;
  border: 3px solid #fff;

  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  font-size: 1.3rem;
  cursor: pointer;
  border-radius: 20px;
  transition: all 0.2s ease-in;

  &:hover {
    border: 3px solid #E51F1F;
  }

  i {
    margin-bottom: 10px;
    font-size: 2rem;
  }
}
