@import './colors.scss';

.header {
  display: flex;
  justify-content: center;
  align-items: center;
}

.loginContainer {
  padding: 30px;
  background-color: #fff;
  border-radius: 20px;
  max-width: 800px;
  margin: 0 auto;

  input {
    background-color: #eee !important;
  }
}
