@import './reset.scss';
@import './colors.scss';
@import './inputs.scss';

* {
  box-sizing: border-box;
  font-size: 16px;
  color: $color;
  line-height: 1.6;
}

.error {
  padding: 20px;
  text-align: center;
  background-color: #fff;
  border-radius: 20px;
  font-weight: 600;
  padding: 20px 0px;
  max-width: 800px;
  margin: 0 auto;
  margin-bottom: 20px;
}

#root {
  min-height: 100vh;
  display: grid;
  grid-template-rows: 200px 1fr 80px;
  background-color: #eee;
}

main {
  padding: 20px;
}

main, footer {
  font-family: 'Open Sans', sans-serif !important;
}


h2 {
  font-size: 2rem;
  margin-bottom: 40px;
}

label {
  font-weight: bolder;
}

.btn {
  height: 44px;
  background-color: $accent;
  color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  border-radius: 5px;
  cursor: pointer;
}

.title {
  color: #484848;
  text-align: center;
  font-size: 2rem;
  position: relative;
  margin-top: 50px;
  margin-bottom: 50px;
  font-weight: 700;
  padding-bottom: 5px;
  line-height: normal;
  font-family: "Averia Serif Libre",cursive;
  &:before {
    content: "";
    width: 100px;
    height: 2px;
    background-color: $accent;
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    margin: 0 auto;
    margin-top: 10px;
  }
}
